<template>
  <div class="card">
    <div class="card-header">
      Course Types
    </div>
    <div class="card-body">
      <FormInput
        v-model="form.name"
        label="Course Type Name"
      />
      <div
        class="btn btn-success btn-sm btn-block mt-3"
        @click="sendForm"
      >
        <i class="fas fa-save mr-3" />
        Create New Course Type
      </div>

      <hr class="my-5">

      <vue-table
        v-if="vTable.headers"
        ref="vtable"
        :values="course_types"
        :headers="vTable.headers"
        :actions="vTable.actions"
        :options="vTable.options"
        @changed="getData"
        @editableInput="onChangeEditable"
        @onPressSave="onPressSave"
        @onDelete="onDelete"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FormInput } from '@/components/inputs';

export default {
  components: {
    FormInput,
  },
  data: () => ({
    form: {},
    vTable: {
      headers: [
        {
          title: 'id',
          sortable: true,
        },
        {
          title: 'name',
          sortable: true,
          editable: 'text',
        },
      ],
      actions: [
        {
          buttonClass: 'btn-success btn-sm',
          callback: 'onPressSave',
          tooltip: 'Save',
          icon: 'fas fa-save',
        },
        {
          buttonClass: 'btn-danger btn-sm',
          callback: 'onDelete',
          tooltip: 'Delete',
          icon: 'fas fa-times',
        },
      ],
      options: {
        tableClass: 'table table-striped',
      },
    },
  }),
  computed: {
    ...mapGetters([
      'course_types',
    ]),
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    ...mapActions([
      'getCourseTypes',
      'createCourseType',
      'updateCourseType',
      'deleteCourseType',
    ]),
    getData(evt = {}) {
      this.getCourseTypes(evt).catch(this.showError);
    },
    resetTable() {
      this.$refs.vtable.init();
    },
    onChangeEditable(index, column, value) {
      const courseType = this.course_types.data[index];
      courseType[column] = value;
      courseType.edited = true;
    },
    onPressSave(item) {
      if (item.edited) {
        this.updateCourseType(item)
          .then(() => {
            this.$noty.success('Course Type updated succesfully');
            item.edited = false;
          })
          .catch(this.showError);
      } else {
        this.$noty.info('Edit some value to be able to save this item.');
      }
    },
    onDelete(item) {
      this.fireConfirm().then(() => {
        this.deleteCourseType(item)
          .then(() => {
            this.$noty.success('Course Type deleted succesfully');
            this.resetTable();
          })
          .catch(this.showError);
      });
    },
    sendForm() {
      this.createCourseType(this.form)
        .then(() => {
          this.$noty.success('Course Type created successfully');
          this.resetTable();
        })
        .catch(this.showError);
    },
  },
};
</script>

<style>

</style>
